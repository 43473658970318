.page-template {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #121212;
    color: #e0e0e0;
    font-family: 'Tajawal', sans-serif;
  }
  
  .page-header {
    height: var(--header-height);
    width: 100%;
    background-color: #121212;
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 100;
  }
  
  .nav-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .page-content {
    flex: 1;
    margin-top: var(--header-height);
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--header-height));
    overflow: hidden;
  }
  