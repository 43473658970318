.page-template {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--color-bg-primary);
}

.page-header {
  height: var(--header-height);
  border-bottom: 1px solid var(--color-border-primary);
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.page-layout {
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr;
  height: calc(100vh - var(--header-height));
}

.sidebar {
  background-color: var(--color-bg-secondary);
  border-right: 1px solid var(--color-border-primary);
  padding: 1rem;
}

.main-content {
  padding: 1.5rem;
  overflow-y: auto;
}
