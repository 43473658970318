/* src/components/SysAdminPage/SysAdminPage.css */

.sysadmin-terminal {
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: #121212;
}

/* Additional styles to ensure terminal styling */
.xterm {
    font-family: 'Fira Code', monospace; /* Custom font */
    font-size: 14px;
    text-align: left;
    background-color: #121212 !important; /* Ensure consistent background */
    color: #e0e0e0;
}

/* Remove the .sysadmin-page class as it's no longer needed */

  