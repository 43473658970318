/* src/components/DevOpsPage/DevOpsPage.css */
.page-template {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #121212; /* Dark theme background */
}

.page-header {
  padding: 1rem;
  border-bottom: 1px solid #444;
}

.page-layout {
  display: grid;
  grid-template-columns: 250px 1fr; /* Sidebar and Content */
  height: 100%; /* Changed from calc to use parent container height */
  overflow: hidden;
}

.main-content {
  overflow-y: auto;
  height: 100%;
}

.left-menu {
  background-color: var(--color-sidebar-bg); /* Use Primer's CSS variable for consistency */
  padding: 16px;
  border-right: 1px solid var(--color-border-default);
}
.left-menu ul {
  padding: 0;
  list-style-type: none;
}


.right-content {
  padding: 1.5rem;
  background-color: #1a1d23; /* Content background */
  color: #ffffff;
}

/* Dark Theme Overrides with Primer Design Tokens */
.page-template {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.page-header {
  padding: 1rem;
  border-bottom: 1px solid var(--color-border-muted); /* Border for dark mode */
}

.page-layout {
  display: grid;
  grid-template-columns: 250px 1fr; /* Sidebar and Content */
  height: 100%; /* Changed from calc to use parent container height */
  overflow: hidden;
}

.main-content {
  overflow-y: auto;
  height: 100%;
}

.left-menu {
  background-color: var(--color-bg-canvas-inset); /* Sidebar background for dark mode */
  padding: 1rem;
  border-right: 1px solid var(--color-border-muted);
}

.left-menu ul {
  list-style: none;
  padding: 0;
}

.left-menu li {
  margin-bottom: 1rem;
}

.left-menu a {
  color: var(--color-fg-default); /* Sidebar text color */
  text-decoration: none;
}

.left-menu a:hover {
  text-decoration: underline;
}

.right-content {
  padding: 1.5rem;
  background-color: var(--color-bg-canvas); /* Content background in dark mode */
  color: var(--color-fg-default);
}

.Box-header {
  background-color: var(--color-bg-canvas-inset);
}

.Box-row {
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--color-border-muted);
}

.Box-row:last-child {
  border-bottom: none;
}

.workflow-runs {
  background-color: var(--color-bg-canvas-inset);
  border-radius: 6px;
}

.divider {
  border-top: 1px solid var(--color-border-muted);
  margin: 1rem 0;
}

.form-control {
  background-color: var(--color-bg-canvas);
  color: var(--color-fg-default);
  border: 1px solid var(--color-border-muted);
}

.form-control::placeholder {
  color: var(--color-fg-muted); /* Placeholder color */
}

.sidebar {
  background-color: var(--color-bg-secondary);
  border-right: 1px solid var(--color-border-primary);
  height: 100%;
}
