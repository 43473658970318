.navigation-buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
}

.nav-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 0.5rem;
  color: #e0e0e0;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
}

.button-icon {
  width: 1.25rem;
  height: 1.25rem;
  opacity: 0.9;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

.nav-button:active {
  transform: translateY(0);
}

.nav-button span {
  white-space: nowrap;
}

/* Add subtle glow effect on hover */
.nav-button:hover .button-icon {
  opacity: 1;
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.3));
}
